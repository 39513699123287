<template>
  <component v-if="!asyncJobId" :is="componentName" :element="element" />
  <component v-else :is="componentName" :element="element" :async-loading="asyncLoading" :async="true" />
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Axios, { CancelTokenSource } from 'axios';
import { ILayoutTypeElement } from '@/interfaces/element';
import LayoutElementBlueprint from '@/components/elements/LayoutElementBlueprint.vue';
import LayoutElementRow from '@/components/elements/LayoutElementRow.vue';
import LayoutElementContainer from '@/components/elements/LayoutElementContainer.vue';
import LayoutElementColumn from '@/components/elements/LayoutElementColumn.vue';
import LayoutElementButton from '@/components/elements/LayoutElementButton.vue';
import LayoutElementAccordion from '@/components/elements/LayoutElementAccordion.vue';
import LayoutElementTextField from '@/components/elements/LayoutElementTextField.vue';
import LayoutElementDivider from '@/components/elements/LayoutElementDivider.vue';
import LayoutElementSvgObject from '@/components/elements/LayoutElementSvgObject.vue';
import LayoutElementTextarea from '@/components/elements/LayoutElementTextarea.vue';
import LayoutElementSettingsNavigation from '@/components/elements/LayoutElementSettingsNavigation.vue';
import LayoutElementDeviceSwitch from '@/components/elements/LayoutElementDeviceSwitch.vue';
import LayoutElementMedia from '@/components/elements/LayoutElementMedia.vue';
import LayoutElementHidden from '@/components/elements/LayoutElementHidden.vue';
import LayoutElementSection from '@/components/elements/LayoutElementSection.vue';
import LayoutElementCheckbox from '@/components/elements/LayoutElementCheckbox.vue';
import LayoutElementCheckboxes from '@/components/elements/LayoutElementCheckboxes.vue';
import LayoutElementRadio from '@/components/elements/LayoutElementRadio.vue';
import LayoutElementSelect from '@/components/elements/LayoutElementSelect.vue';
import LayoutElementSwitch from '@/components/elements/LayoutElementSwitch.vue';
import LayoutElementColorpicker from '@/components/elements/LayoutElementColorpicker.vue';
import LayoutElementHeadline from '@/components/elements/LayoutElementHeadline.vue';
import LayoutElementParagraph from '@/components/elements/LayoutElementParagraph.vue';
import LayoutElementTable from '@/components/elements/LayoutElementTable.vue';
import LayoutElementTagsInput from '@/components/elements/LayoutElementTagsInput.vue';
import LayoutElementCodeEditor from '@/components/elements/LayoutElementCodeEditor.vue';
import LayoutElementTextEditor from '@/components/elements/LayoutElementTextEditor.vue';
import LayoutElementImage from '@/components/elements/LayoutElementImage.vue';
import LayoutElementIcon from '@/components/elements/LayoutElementIcon.vue';
import LayoutElementDatepicker from '@/components/elements/LayoutElementDatepicker.vue';
import LayoutElementBadge from '@/components/elements/LayoutElementBadge.vue';
import LayoutElementMessage from '@/components/elements/LayoutElementMessage.vue';
import LayoutElementTabs from '@/components/elements/LayoutElementTabs.vue';
import LayoutElementIndicator from '@/components/elements/LayoutElementIndicator.vue';
import LayoutElementDevicePreview from '@/components/elements/LayoutElementDevicePreview.vue';
import LayoutElementLink from '@/components/elements/LayoutElementLink.vue';
import LayoutElementSize from '@/components/elements/LayoutElementSize.vue';
import LayoutElementDictionary from '@/components/elements/LayoutElementDictionary.vue';
import LayoutElementPositioner from '@/components/elements/LayoutElementPositioner.vue';
import LayoutElementChart from '@/components/elements/LayoutElementChart.vue';
import LayoutElementStepGuide from '@/components/elements/LayoutElementStepGuide.vue';
import LayoutElementSlider from '@/components/elements/LayoutElementSlider.vue';
import LayoutElementTimepicker from '@/components/elements/LayoutElementTimepicker.vue';
import LayoutElementRecaptcha from '@/components/elements/LayoutElementRecaptcha.vue';
import LayoutElementGoogleMap from '@/components/elements/LayoutElementGoogleMap.vue';
import LayoutElementNavigation from '@/components/elements/LayoutElementNavigator.vue';
import LayoutElementEmbed from '@/components/elements/LayoutElementEmbed.vue';
import LayoutElementContextmenu from '@/components/elements/LayoutElementContextmenu.vue';
import LayoutElementDateTimepicker from './elements/LayoutElementDateTimepicker.vue';
import LayoutElementTransitionGroup from '@/components/elements/LayoutElementTransitionGroup.vue';
import LayoutElementVerificationCode from '@/components/elements/LayoutElementVerificationCode.vue';
import LayoutElementSkeletonLoader from '@/components/elements/LayoutElementSkeletonLoader.vue';
import LayoutElementCard from '@/components/elements/LayoutElementCard.vue';
import LayoutElementDebugger from '@/components/elements/LayoutElementDebugger.vue';
import LayoutElementSecret from '@/components/elements/LayoutElementSecret.vue';
import LayoutElementPhoneNumber from '@/components/elements/LayoutElementPhoneNumber.vue';
import { AppRequest } from '@/app_request';
import { EventBus } from '@/main';
import { autobind } from 'core-decorators';
import LayoutElementIconSelector from '@/components/elements/LayoutElementIconSelector.vue';
import { PropType } from 'vue';

interface AsyncRenderResponse {
  status: 'queued' | 'failed' | 'finished';
  output?: {
    element?: ILayoutTypeElement;
  };
}

@Component({
  components: {
    LayoutElementBlueprint,
    LayoutElementRow,
    LayoutElementContainer,
    LayoutElementColumn,
    LayoutElementButton,
    LayoutElementTextField,
    LayoutElementTextarea,
    LayoutElementMedia,
    LayoutElementHidden,
    LayoutElementSection,
    LayoutElementCheckbox,
    LayoutElementCheckboxes,
    LayoutElementRadio,
    LayoutElementSelect,
    LayoutElementSwitch,
    LayoutElementDivider,
    LayoutElementColorpicker,
    LayoutElementHeadline,
    LayoutElementParagraph,
    LayoutElementTable,
    LayoutElementTagsInput,
    LayoutElementTextEditor,
    LayoutElementCodeEditor,
    LayoutElementImage,
    LayoutElementIcon,
    LayoutElementDatepicker,
    LayoutElementBadge,
    LayoutElementMessage,
    LayoutElementTabs,
    LayoutElementLink,
    LayoutElementAccordion,
    LayoutElementSize,
    LayoutElementDevicePreview,
    LayoutElementIndicator,
    LayoutElementDictionary,
    LayoutElementPositioner,
    LayoutElementChart,
    LayoutElementStepGuide,
    LayoutElementSlider,
    LayoutElementTimepicker,
    LayoutElementSettingsNavigation,
    LayoutElementRecaptcha,
    LayoutElementGoogleMap,
    LayoutElementNavigation,
    LayoutElementEmbed,
    LayoutElementContextmenu,
    LayoutElementDateTimepicker,
    LayoutElementTransitionGroup,
    LayoutElementVerificationCode,
    LayoutElementSkeletonLoader,
    LayoutElementDeviceSwitch,
    LayoutElementCard,
    LayoutElementSvgObject,
    LayoutElementIconSelector,
    LayoutElementDebugger,
    LayoutElementSecret,
    LayoutElementPhoneNumber
  },
  name: 'layout-element'
})
export default class LayoutElement extends Vue {
  @Prop({ type: Object as PropType<ILayoutTypeElement> }) public readonly element!: ILayoutTypeElement;

  public elementType = this.element?.type?.replace('_', '-') ?? null;
  public asyncLoading = false;
  public asyncLastIdProcessed: number | undefined;

  private asyncSource: CancelTokenSource | null = null;
  private asyncInterval: number | undefined;

  created() {
    EventBus.$on('TRIGGER_UPDATE', this.onUpdate);
  }

  destroyed() {
    EventBus.$off('TRIGGER_UPDATE', this.onUpdate);
  }

  @Watch('element.scrollIntoView')
  public onScrollIntoView() {
    if (this.element.scrollIntoView) {
      this.$el.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });

      this.element.scrollIntoView = false;
    }
  }

  @autobind
  onUpdate() {
    if (this.asyncSource !== null) {
      this.asyncSource.cancel();
      this.asyncSource = null;
    }

    if (this.asyncInterval) {
      clearInterval(this.asyncInterval);
    }
  }

  // eslint-disable-next-line require-await
  @Watch('asyncJobId', { immediate: true })
  async loadAsync() {
    if (this.asyncJobId && this.asyncJobId !== this.asyncLastIdProcessed) {
      this.asyncLoading = true;

      if (this.asyncSource !== null) {
        this.asyncSource.cancel();
        this.asyncSource = null;
      }

      if (this.asyncInterval) {
        clearInterval(this.asyncInterval);
      }

      setTimeout(this.asyncIntervalHandler, 2500);
      this.asyncInterval = setInterval(this.asyncIntervalHandler, 5000);
    }
  }

  async asyncIntervalHandler() {
    this.asyncSource = Axios.CancelToken.source();

    if (!this.asyncJobId) {
      return;
    }

    const response = await AppRequest.get<AsyncRenderResponse>(`/api/v1/job/${this.asyncJobId}`, {
      cancelToken: this.asyncSource.token,
      headers: {
        accept: 'application/json'
      },
      responseType: 'json'
    });

    switch (response.data.status) {
      case 'failed':
      case 'finished':
        clearInterval(this.asyncInterval);
        this.asyncLoading = false;

      // eslint-disable-next-line no-fallthrough
      case 'finished':
        if (response.data.output?.element?.properties && this.element.properties) {
          for (const [key, value] of Object.entries(response.data.output.element.properties)) {
            Vue.set(this.element.properties, key, value);
          }
        }
        break;

      case 'failed':
        break;
    }

    this.asyncSource = null;
  }

  get asyncJobId() {
    if (this.element?.properties && 'asyncJob' in this.element.properties && this.element.properties.asyncJob) {
      return this.element.properties.asyncJob;
    }

    return undefined;
  }

  get componentName() {
    return 'layout-element-' + this.element?.type?.replace('_', '-');
  }
}
</script>
